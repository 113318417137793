import * as React from 'react'
import '../assets/css/index.css'
import '../assets/css/Images.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='Snooker - Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for a Snooker club in Norwich? With 5 great condition tables why not book a table before or come down to see if we have any availability'
        />
      <div className='green-section'>
      <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="pb-3 green-section ">
                <h1 className='pt-3 pb-3'>
                Snooker Table Information
                </h1>
                <p>We currently have 5 snooker tables which are all heated and are steel-blocked meaning they are more responsive to play on than a normal snooker table. These are re-clothed frequently to ensure that the condition of the table are maintained to a high level. Our 2 match tables have cloths similar to what you would experience on the professional circuit.</p>
                <hr></hr>
                <h2 className='pb-3'>
                  Hourly Rate
                  </h2>
                  <p>10:00 - 12:00 | £4.00 per hour</p>
                  <p>12:00 - 18:00 | £6.00 per hour</p>
                  <p>18:00 - 22:00 | £7.00 per hour</p>
                  <p>Snooker match tables are an additional £2 per hour, these are tables 9 and 10.</p>
                  <hr></hr>
                  <h2 className='pb-3'>
                  Local Norwich Snooker Teams
                  </h2>
                
                  <p>Information on league team results can be found</p>
                  <Button href="https://www.leaguesnooker.co.uk/League/County.asp?County=Norfolk" variant="dark" target="_blank">UK League Snooker</Button>
                  <hr></hr>
            </Col>

            
      
            <Col lg={6} md={12} sm={12} xs={12} className="green-section ">
              <StaticImage src="../assets/img/Norwich-Pinches-Cue-Club-1 (9).jpg"
                    alt="A hero image"
                    placeholder="blurred"
                    layout="constrained" />
            </Col>
            <Col lg={6} md={12} sm={12} xs={12} className="green-section pb-4">
              <StaticImage src="../assets/img/Norwich-Pinches-Cue-Club-1 (8).jpg"
                    alt="A hero image"
                    placeholder="blurred"
                    layout="constrained"
                     />
            </Col>
            <br></br>
            <hr></hr>
            </Row>
            </Container>
            </div>
      <div className='black-section'>
      <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="black-section ">
                  <h3 className='pt-3 pb-3'>
                  Norwich Snooker Tournament Information 
                  </h3>
                  <p>Pinches Cue Club runs monthly handicap snooker tournaments, you can find out more by contacting the club. These are normally run on a Sunday and handicaps are set by the tournament organiser and limited to 32 players.</p>
              </Col>
              <hr></hr>
                <Col lg={12} md={12} sm={12} xs={12} className="black-section ">
                  <h3 className='pt-3 pb-3'>
                  Resident Professional Snooker player Barry Pinches 
                  </h3>
                  <div>
                    <p>Barry has been a professional snooker player since the late 1980's, having been a ranking quarter-finalist twice and winner of a PTC ranking event in 2010 beating Mark Williams in the semi-final and Ronnie O'sullivan in the final. His son Luke also has similar ambitions to follow his father. </p>
                    <br></br>
                  </div>
                  <p>To see the latests professional results for Barry please use the button below</p>
                  <Button href="https://cuetracker.net/players/barry-pinches" variant="success" target="_blank">Cue Tracker</Button>
                  <br></br>
                  <br></br>
                  <StaticImage src="../assets/img/barry-luke-pinches.png"
                      alt="A hero image"
                      placeholder="blurred"
                      layout="constrained"
                      className='pinches-img' />
                </Col>
              </Row>
      </Container>
      <Footer/>
      </div>
    </main>
  )
}

export default IndexPage
